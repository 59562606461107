import React from 'react';
import { Row, Col } from 'reactstrap';
import Logo from '../../assets/ringgit-lab.png';

const LoadingSpinner = () => {
  return (
    <Row>
      <Col className="align-self-center">
        <div className="spinner">
          <img src={Logo} className="w-100" alt="Ringgit Lab" />
        </div>
        <div className="loading-spinner">
          <p>Loading ...</p>
        </div>
      </Col>
    </Row>
  );
};

export default LoadingSpinner;